import React from 'react';
import iconX from "./images/icon-filter-x.svg";

const RegionStatus = ({region, handleClearSingleRegion}) => {
    return (
      <>
        {region.map((item, index) => {
          return (
              <div key={index}
                   className={'btn-partner status show'}
              >
                <span>{item}</span>
                <img
                    src={iconX}
                    data-region={item}
                    onClick={handleClearSingleRegion}
                    alt="X"
                    width={'8'}
                    height={'8'}/>
              </div>
          );
        })}
      </>
      )
};

export default RegionStatus;
