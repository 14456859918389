import React from 'react';
import iconX from "./images/icon-filter-x.svg";

const ProductStatus = ({product, handleClearSingleProduct}) => {
  return (
      <>
        {product.map((item, index) => {
          return (
              <div key={item} className={'btn-partner status show'}>
                <span>{item}</span>
                <img
                    src={iconX}
                    data-product={item}
                    onClick={handleClearSingleProduct}
                    alt="X"
                    width={'8'}
                    height={'8'}/>
              </div>
          );
        })}
      </>
  )
};

export default ProductStatus;
