import React, {useState} from 'react';
import pulldownArrow from './images/filter-menu-down.svg';
import pulldownArrowWhite from './images/filter-menu-down-white.svg';


const FilterMenu = ({items, label, cls, checkedState, handleCheckedState, menuOpen, handleMenuClick}) => {

  return (
      <div className={'dropdown '+cls}>
        <div className={menuOpen ? 'dropdown-label open' : 'dropdown-label'} onClick={handleMenuClick}>
          <span>{label}</span>
          <img src={menuOpen ? pulldownArrowWhite : pulldownArrow} alt="arrow" width={'12'} height={'7'}/>
        </div>
        <div className={menuOpen ? 'dropdown-menu open' : 'dropdown-menu'}>
          {items.map(({id, name, value}, index) => {
            return (
                <div>
                  <input
                      type="checkbox"
                      id={id}
                      key={id}
                      data-count={index}
                      name={name}
                      value={value}
                      checked={checkedState[index]}
                      onChange={handleCheckedState}
                  />
                  <label htmlFor={id}>{name}</label>
                </div>
            );
          })}
        </div>
      </div>
      )
};

export default FilterMenu;
