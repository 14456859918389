import React, {useState, useEffect} from 'react';
import {useStaticQuery, graphql} from "gatsby";
import {Trans} from "react-i18next";

import FilterMenu from "./filter-menu";
import RegionStatus from "./region-status";
import ProductStatus from "./product-status";
import iconFilters from './images/icon-filters.svg';
import iconAsc from './images/icon-asc-dec.svg';
import iconXDark from './images/icon-filter-dark-x.svg';
import iconClose from './images/icon-filter-close.svg';

import {partnerRegions} from "../../data/json/partners/regions";
import {productTypes} from "../../data/json/partners/product-types";

const Partners = () => {
  const partners = useStaticQuery(graphql`
    query MyQuery {
  allPartners {
    edges {
      node {
        id
        company_name
        city
        company_type
        country
        location
        state
        website
      }
    }
  }
}

  `);

  const [query, setQuery] = useState('');
  const keys = ['company_name', 'city', 'state', 'country'];
  const [sortOrder, setSortOrder] = useState('asc');

  const [filterScreen, setFilterScreen] = useState('hide');
  const [region, setRegion] = useState([]);
  const [checkedRegionState, setCheckedRegionState] = useState(new Array(partnerRegions.length).fill(false));
  const [product, setProduct] = useState([]);
  const [checkedProductState, setCheckedProductState] = useState(new Array(productTypes.length).fill(false));
  const [regionMenuOpen, setRegionMenuOpen] = useState(false);
  const [productMenuOpen, setProductMenuOpen] = useState(false);

  let sortedPartners = [...partners.allPartners.edges].sort((a, b) => {
    if (sortOrder === 'asc') {
      if (a.node.company_name < b.node.company_name) {
        return -1;
      }
      if (a.node.company_name > b.node.company_name) {
        return 1;
      }
    } else {
      if (a.node.company_name < b.node.company_name) {
        return 1;
      }
      if (a.node.company_name > b.node.company_name) {
        return -1;
      }
    }
  });
  function removeJsonComment(a) {
    switch (a.node.company_name) {
      case 'ASIA':
        return false;
      case 'LATIN':
        return false;
      case 'MIDDLE EAST':
        return false;
      case 'EUROPE':
        return false;
      default:
        return true;
    }
  }
  function sortByRegion(a) {
    if (region.length === 0) {
      return true;
    }
    if (region.indexOf(a.node.location) > -1) {
      return true;
    }
  }
  function filterBySearch(a) {
    return keys.some((key) => a.node[key].toLowerCase().includes(query));
  }
  let handleClearAll = () => {
    setRegion([]);
    setCheckedRegionState(new Array(partnerRegions.length).fill(false));
    setProduct([]);
    setCheckedProductState(new Array(productTypes.length).fill(false));
    setRegionMenuOpen(false);
    setProductMenuOpen(false);
  }
  let handleClearSingleRegion = (e) => {
    //clear selected region
    let reg = e.target.getAttribute('data-region');
    let newArray = region.filter(item => item !== reg);
    setRegion(newArray);
    //clear selected check
    for(let i=0; i< partnerRegions.length; i++) {
      if(partnerRegions[i].name === reg) {
        setCheckedRegionState(prevState => prevState.map((item, index) => (i=== index ? false : item)))
      }
    }

  }
  let handleClearSingleProduct = (e) => {
    //clear selected region
    let prod = e.target.getAttribute('data-product');
    let newArray = product.filter(item => item !== prod);
    setProduct(newArray);

    //clear selected check
    for(let i=0; i< productTypes.length; i++) {
      if(productTypes[i].name.toLowerCase() === prod.toLowerCase()) {
        setCheckedProductState(prevState => prevState.map((item, index) => (i=== index ? false : item)))
      }
    }

  }
  let handleFilterPanel = () => {
    let navOverlay = document.getElementsByClassName('nav-overlay')[0];
    let bodyElm = document.body;
    if (filterScreen === 'hide') {
      navOverlay.classList.add('show');
      bodyElm.classList.add('stop-body-scroll');
      setFilterScreen('show');
    } else {
      navOverlay.classList.remove('show');
      bodyElm.classList.remove('stop-body-scroll');
      setFilterScreen('hide');
    }
    setRegionMenuOpen(false);
    setProductMenuOpen(false);
  }
  let handleRegionChange = (event) => {
    const {value, checked} = event.target;
    const count = parseInt(event.target.getAttribute('data-count'));
    //update checkbox array
    const updatedCheckedRegionState = checkedRegionState.map((item, index) =>
        index === count ? !item : item
    );
    setCheckedRegionState(prevState => updatedCheckedRegionState);
    //now update region array
    if (checked) {
      //push item
      setRegion((prevRegion) => [...prevRegion, value]);
    } else {
      //pop item
      setRegion((prevRegion) =>
          prevRegion.filter((item) => item !== value)
      );
    }

  }
  let handleProductChange = (event) => {
    const {value, checked} = event.target;
    const count = parseInt(event.target.getAttribute('data-count'));
    //update checkbox array
    const updatedCheckedProductState = checkedProductState.map((item, index) =>
        index === count ? !item : item
    );
    setCheckedProductState(prevState => updatedCheckedProductState);
    //now update region array
    if (checked) {
      //push item
      setProduct((prevProduct) => [...prevProduct, value]);
    } else {
      //pop item
      setProduct((prevProduct) =>
          prevProduct.filter((item) => item !== value)
      );
    }

  }
  let handleRegionMenuOpen = () => {
    setRegionMenuOpen(!regionMenuOpen);
    setProductMenuOpen(false);
  }
  let handleProductMenuOpen = () => {
    setProductMenuOpen(!productMenuOpen);
    setRegionMenuOpen(false);
  }
  let windowClickHandler = (e) => {
    let regionMenu = document.getElementsByClassName('region')[0];
    let regionMenuMobile = document.getElementsByClassName('region')[1];
    let productMenu = document.getElementsByClassName('product')[0];
    let productMenuMobile = document.getElementsByClassName('product')[1];
    if (!regionMenu.contains(e.target) &&
        !productMenu.contains(e.target) &&
        !regionMenuMobile.contains(e.target) &&
        !productMenuMobile.contains(e.target)
    ){
      setProductMenuOpen(false);
      setRegionMenuOpen(false);
    }
  }

  useEffect(() => {
    window.addEventListener('click', windowClickHandler);
    return () => {
      window.removeEventListener('click', windowClickHandler);
    };
  }, []);
  return (
      <>
        <section className="find-partner">
          <div className="container">
            <h2>
              <Trans>
                find_partner
              </Trans>
            </h2>
          </div>
        </section>
        <section>
          <div class="container">
            <nav class="filters">
              <div className={'filter-controls'}>
                <div class="spacer">
                  &nbsp;
                </div>
                <input
                    type="search" placeholder={'Search all partners'}
                    className={'btn-partner'}
                    onChange={e => setQuery(e.target.value.toLowerCase())}
                />
                <FilterMenu
                    items={partnerRegions}
                    label={'Region'}
                    cls={'region'}
                    checkedState={checkedRegionState}
                    handleCheckedState={handleRegionChange}
                    menuOpen={regionMenuOpen}
                    handleMenuClick={handleRegionMenuOpen}
                />
                <FilterMenu
                    items={productTypes}
                    label={'Product Type'}
                    cls={'product'}
                    checkedState={checkedProductState}
                    handleCheckedState={handleProductChange}
                    menuOpen={productMenuOpen}
                    handleMenuClick={handleProductMenuOpen}
                />
                <div
                    className="btn-partner filters"
                    onClick={handleFilterPanel}
                >
                  <img src={iconFilters} alt="filters" width={'16'} height={'15'}/>
                  Filters
                </div>
                <div
                    className={'btn-partner asc-dec'}
                    onClick={() => setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')}>
                  <img src={iconAsc} alt="sort" width={'18'} height={'15'}/>
                  ({sortOrder === 'asc' ? 'A-Z' : 'Z-A'})
                </div>
              </div>
              <div class="filter-status">
                <RegionStatus region={region} handleClearSingleRegion={handleClearSingleRegion} />
                <ProductStatus product={product} handleClearSingleProduct={handleClearSingleProduct} />
                <div className={region.length === 0 && product.length === 0 ? 'btn-partner status hide' : 'btn-partner status'} onClick={handleClearAll}>
                  <span>Clear all</span>
                  <img src={iconXDark} alt="X" width={'8'} height={'8'}/>
                </div>
              </div>
              <div class={filterScreen === 'hide' ? 'filter-mobile-screen hide' : 'filter-mobile-screen'}>
                <div
                    className="btn-partner filters label"
                >
                  <img src={iconFilters} alt="filters" width={'16'} height={'15'}/>
                  Filters
                </div>
                <img
                    className={'closer'}
                    onClick={handleFilterPanel}
                    src={iconClose}
                    alt="close"
                    width={'34'}
                    height={'34'}/>
                <FilterMenu
                    items={partnerRegions}
                    label={'Region'}
                    cls={'region'}
                    checkedState={checkedRegionState}
                    handleCheckedState={handleRegionChange}
                    menuOpen={regionMenuOpen}
                    handleMenuClick={handleRegionMenuOpen}
                />
                <FilterMenu
                    items={productTypes}
                    label={'Product Type'}
                    cls={'product'}
                    checkedState={checkedProductState}
                    handleCheckedState={handleProductChange}
                    menuOpen={productMenuOpen}
                    handleMenuClick={handleProductMenuOpen}
                />
                <div className="filter-mobile-screen-footer">
                  {/*<div className="section-delimeter"/>*/}
                  <div class="butts">
                    <div onClick={handleClearAll} className={'btn btn--white'}>Clear All</div>
                    <div onClick={handleFilterPanel} className={'btn btn--blue'}>See Results</div>
                  </div>
                </div>
              </div>
            </nav>
            <div className="dealer-locations">
              {
                sortedPartners.filter(removeJsonComment).filter(sortByRegion).filter(filterBySearch).map((partner, index) => (
                    <div className={'dealer'} key={index}>
                      <h4>
                        {partner.node.company_name}
                      </h4>
                      <p>
                        {partner.node.city}, {partner.node.state} <br/>
                        {partner.node.country} <br/>
                        <a
                            href={partner.node.website}
                            target={'_blank'}
                            rel={'noreferrer'}
                        >
                          Visit Website
                        </a>
                      </p>
                    </div>
                ))
              }
            </div>
          </div>
        </section>
      </>

  )
};

export default Partners;
