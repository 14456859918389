export const partnerRegions = [
  {
    id: 'northAmerica',
    name: 'North America',
    value: 'North America'
  },
  {
    id: 'EMEA',
    name: 'EMEA',
    value: 'EMEA'
  },
  {
    id: 'middleEast',
    name: 'Middle East',
    value: 'Middle East'
  },
  {
    id: 'asiaOceania',
    name: 'Asia',
    value: 'Asia'
  },
  {
    id: 'latinAmerica',
    name: "Latin America",
    value: 'Latin America'
  }
]

