export const productTypes = [
  {
    id: 'locks',
    name: 'Locks',
    value: 'locks'
  },
  {
    id: 'locks_lockers',
    name: 'Locks + Lockers',
    value: 'Locks + Lockers'
  },
  {
    id: 'locks_furniture',
    name: 'Locks + Furniture',
    value: 'Locks + Furniture'
  }
]

