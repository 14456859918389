import React, {useEffect, useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../components/layout";
import Seo from "../../components/seo";
import {graphql} from 'gatsby';
import CompanySettings from "../../constants/company/settings";
import Partners from '../../components/dealers/partners';
import ContinentSettings from "../../constants/continents/geo-continents";
import LiveChatButton from "../../components/contact/live-chat-button";
import {StaticImage} from "gatsby-plugin-image";
import CustomLocalizedLink from "../../components/locales/custom-localized-link";


const IndexPage = () => {
  const {t} = useTranslation();
  const [salesEmail, setSalesEmail] = useState(CompanySettings.SALES_EMAIL);
  const [salesPhone, setSalesPhone] = useState(CompanySettings.PHONE_SALES_AMERICAS);

  function handleScrollTo(elmID) {
    window.scroll({
      behavior: 'smooth',
      left: 0,
      top: document.getElementById(elmID).offsetTop - 150
    });
  }

  useEffect(() => {
    if (document.location.hash === '#gotoPartners') {
      handleScrollTo("partners");
    }

    if (localStorage.getItem(ContinentSettings.SESSION_CONTINENT) !== null) {
      let cs = localStorage.getItem(ContinentSettings.SESSION_CONTINENT);
      if (cs === ContinentSettings.ASIA) {
        setSalesEmail(CompanySettings.SALES_EMAIL_ASIA);
        setSalesPhone(CompanySettings.PHONE_SALES_ASIA);
      } else if (cs === ContinentSettings.EUROPE) {
        setSalesEmail(CompanySettings.SALES_EMAIL_EUROPE);
        setSalesPhone(CompanySettings.PHONE_SALES_EUROPE);
      }
    }

  }, []);

  return (
      <Layout>
        <Seo
            title={t('where_to_buy')}
            description={t('des_where_to_buy')}
        />
        <div className="where-to-buy">
          <section className="hero --hero-image no-pad">
            {/*<StaticImage*/}
            {/*    src="../../images/about/where-to-buy.jpg"*/}
            {/*    quality={100}*/}
            {/*    layout={'fullWidth'}*/}
            {/*    formats={["auto", "webp", "avif"]}*/}
            {/*    alt="About Digilock"*/}
            {/*/>*/}
            <div class="group">
              <h1>
                <Trans>
                  where_buy
                </Trans>
              </h1>
              <p>
                <Trans>
                  where_buy_copy
                </Trans>
              </p>
              <div className="buttons">
                <CustomLocalizedLink label={'get in touch'} goto={'/contact/?help=purchaseLocks'} cls={'btn btn--orange'} />
              </div>
            </div>
          </section>
          <Partners/>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
